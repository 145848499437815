import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient,HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ServiceUrl } from 'src/app/api-services/service-url';
import { ReportRequest } from '../../core/models/LoginDetails';
import { EmailProperties } from '../../core/models/EmailProperties';
import { AssignToPharmaModel, PharmaPaymentModel, PremiumUserSearchModel, SubmissionCountSearch, SubmissionOperationModel, SubmissionSearchModel, SubmissionTextSearchModel } from 'src/app/Models/submission-details';
import {LoginResetModel} from 'src/app/Models/login-model';
import { MedicinePackageModel, MedicinePackageSearchModel, PromocodePackageModel, PromocodeSearchModel, UploadExcelModel } from 'src/app/Models/report-model';
import { patientSearchModel } from 'src/app/Models/survey-details';
import { UserMenues } from 'src/app/Models/user-menues';
import { UserMap } from 'src/app/Models/user-map';
import { DeviceDetectorService } from "ngx-device-detector";
// import { isNullOrUndefined } from 'util';
//import 'rxjs/add/operator/map';  

@Injectable({
  providedIn: 'root'
})
export class AdminServicesService {

  adminService = this.dynamicServiceUrls.admin;
  headers = new HttpHeaders().set('Content-Type','application/json')

  webApiUrl = '';
  postData = ''; 
  objLoginDetails: any;





  constructor(private http : HttpClient,
    private dynamicServiceUrls: ServiceUrl,
    private reportRequest: ReportRequest,
    private deviceService: DeviceDetectorService
    //private _snackBar: MatSnackBar
    ) {

     }
     

  errorHandler(error: HttpErrorResponse){
    let errorMessage = `Error code ${error.status}\nMessage: ${error.message}`;
    return throwError(errorMessage);
  }

  getUserLoginDetails(){
    return this.http.get<any>(`${ this.adminService }GetUserLoginDetails`,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getLoginUserTypes() {
    return this.http.get<any>(`${ this.adminService }GetLoginUserTypes`,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  public getIPAddress()  
  {  
    //debugger;
    //return this.http.get("https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json");  
    return this.http.get("http://api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK");  
  }  

  public detectBrowserName() { 
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

 public detectBrowserVersion(){
    var userAgent = navigator.userAgent, tem, 
    matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    
    if(/trident/i.test(matchTest[1])){
        tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return 'IE '+(tem[1] || '');
    }

    if(matchTest[1]=== 'Chrome'){
        tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }

    matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];

    if((tem= userAgent.match(/version\/(\d+)/i))!= null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  public detectClientDeviceMetadata() {
    var deviceInfo: any; 
    var isMobile : boolean;
    var isTablet : boolean;
    var isDesktopDevice : boolean;

    
    deviceInfo = this.deviceService.getDeviceInfo();
    isMobile = this.deviceService.isMobile();
    isTablet = this.deviceService.isTablet();
    isDesktopDevice = this.deviceService.isDesktop();

    var metaData =  deviceInfo.browser + " -> " + 
                deviceInfo.browser_version + " -> " + 
                deviceInfo.deviceType + " -> " + 
                deviceInfo.orientation + " -> " + 
                deviceInfo.os + " -> " + 
                deviceInfo.os_version + " -> " + 
                deviceInfo.userAgent;



    if( isMobile){
      return "Metadata info : " + metaData + " , This is a mobile device."; 
    }
    
    if( isTablet){
      return "Metadata info : " + metaData + " , This is a tablet device."; 
    }

    if( isDesktopDevice){
      return "Metadata info : " + metaData + " , This is a desktop device."; 
    }

  }

  insertUpdateLoginUserDetails (loginUserModel) {
    debugger;
    this.postData = JSON.stringify(loginUserModel);
    console.log('At Service Call (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }InsertUpdateLoginUserDetails`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  InsertIntakeFormLog (intakeformLogModelData) {
    this.postData = JSON.stringify(intakeformLogModelData);
    console.log('At Service Call InsertIntakeFormLog (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }InsertIntakeFormLog`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  deleteLoginUserDetails(id: any){
    return this.http.get<any>(`${ this.adminService }DeleteLoginUserDetails?id=` + id,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getMedicine(){
    return this.http.get<any>(`${this.adminService}GetMedicineDetails`, {headers: this.headers}).pipe(catchError(this.errorHandler));      
  }
  getPrescriptionMedicine(submissionId: number){
    return this.http.get<any>(`${this.adminService}GetPrescriptionMedicine?submissionId=` + submissionId, {headers: this.headers}).pipe(catchError(this.errorHandler));      
  }

  getMedicineCategories(){
    return this.http.get<any>(`${this.adminService}GetMedicineCategory`, {headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  insertMedicine(medicineModel){
    this.postData = JSON.stringify(medicineModel);
    return this.http.post<any>(`${this.adminService}AddMedicineDetails`, this.postData, {headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  DeleteMedicineDetails(id:any){
    //this.postData = JSON.stringify(medicineId);
    return this.http.get<any>(`${this.adminService}DeleteMedicineDetails?id=` +id, {headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getMedicineById(id:any){
    return this.http.get<any>(`${this.adminService}GetMedicineById?id=` +id, {headers:this.headers}).pipe(catchError(this.errorHandler));
  }

  UpdateMedicineDetails(medicineModel){
    this.postData = JSON.stringify(medicineModel);
    return this.http.post<any>(`${this.adminService}UpdateMedicineDetails`, this.postData, {headers:this.headers}).pipe(catchError(this.errorHandler));
  }

  //For format yyyy-MM-dd
  returnFormatedDate(inputDate: any) : any {
    let month = inputDate.getMonth() + 1;
    return inputDate.getFullYear() + '-' + 
     ("0" + month).slice(-2) + '-' + 
     ("0" + inputDate.getDate()).slice(-2);
   }

  GetActivityLogs(reportModel : ReportRequest){
    this.postData = JSON.stringify(reportModel);
    //this.reportRequest = reportModel;
    return this.http.post<any>(`${this.adminService}GetActivityLogs`, this.postData, {headers:this.headers}).pipe(catchError(this.errorHandler));
  }

  sendmailToAllPatient(emailprop: EmailProperties){
    this.postData = JSON.stringify(emailprop);
    return this.http.post<any>(`${ this.adminService }SendMailToPatient`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getPaymentReport(reportRequest: ReportRequest): Observable<any>{
    this.webApiUrl = `${this.adminService}GetPaymentReport`;
    this.postData = JSON.stringify(reportRequest);
    return this.http.post(this.webApiUrl, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getPharmacyList(): Observable<any>{
    this.webApiUrl = `${this.adminService}GetPharmacyList`; 

    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getPharmacyReport(searchModel: any){
    this.postData = JSON.stringify(searchModel);
    return this.http.post<any>(`${ this.adminService }GetPharmacyReport`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  updateDeliveryNote(pharmacyModel) {
    this.postData = JSON.stringify(pharmacyModel);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }updateDeliveryNote`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  UpdateDeliveryNoteBulk(uploadExcelModel : UploadExcelModel[]) {
    this.postData = JSON.stringify(uploadExcelModel);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }UpdateDeliveryNoteBulk`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getPayToPharmacyDetails(searchModel: any){
    this.postData = JSON.stringify(searchModel);
    return this.http.post<any>(`${ this.adminService }GetPayToPharmacyDetails`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getPatientStatus(): Observable<any>{
    this.webApiUrl = `${this.adminService}GetPatientStatus`; 

    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getPatientSurveyType(): Observable<any>{
    this.webApiUrl = `${this.adminService}GetPatientSurveyType`; 

    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getSubmissionDetails(model: SubmissionSearchModel) {
    this.postData = JSON.stringify(model);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }GetSubmissionDetails`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getSubmissionDetailsWithSearch(model: SubmissionTextSearchModel) {
    this.postData = JSON.stringify(model);
    return this.http.post<any>(`${ this.adminService }getSubmissionDetailsWithSearch`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getSubmissionAnswerDetailsBySubmissionId(submissionId: number): Observable<any>{
    this.webApiUrl = `${this.adminService}GetSubmissionAnswerDetailsBySubmissionId?submissionId=` + submissionId; 

    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getDoctors(): Observable<any>{
    this.webApiUrl = `${this.adminService}GetDoctors`; 
    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }
  getPharmacies(): Observable<any>{
    this.webApiUrl = `${this.adminService}GetPharmacies`; 
    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  updateSubmissionDetails(model: SubmissionOperationModel) {
    this.postData = JSON.stringify(model);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }UpdateSubmissionDetails`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getHtmlOfSubmissionAnswerDetailsBySubmissionId(submissionId: number): Observable<any>{
    this.webApiUrl = `${this.adminService}GetHtmlOfSubmissionAnswerDetailsBySubmissionId?submissionId=` + submissionId; 
    //return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
    return this.http.get(this.webApiUrl, {responseType : 'arraybuffer'}).pipe(catchError(this.errorHandler));
  }

  updatePharmacyPaymentDetails(model: PharmaPaymentModel) {
    this.postData = JSON.stringify(model);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }UpdatePharmacyPaymentDetails`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getAccountSummaryReport(model: ReportRequest) {
    this.postData = JSON.stringify(model);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }GetAccountSummaryReport`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getGeneratedPescription(submissionId: number): Observable<any>{
    this.webApiUrl = `${this.adminService}GetGeneratedPescriptionBySubmissionId?submissionId=` + submissionId; 
    return this.http.get(this.webApiUrl, {responseType : 'arraybuffer'}).pipe(catchError(this.errorHandler));
  }

  requestOtpForEmail(requestEmail: string): Observable<any>{
    //this.postData = JSON.stringify(model);
    this.postData = requestEmail;
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }RequestOtpForEmail?requestEmail=` + this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  requestPasswordReset(model: LoginResetModel): Observable<any>{
    this.postData = JSON.stringify(model);
    //this.postData = requestEmail;
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }RequestPasswordReset`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getPackages(model: MedicinePackageSearchModel) {
    this.postData = JSON.stringify(model);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }GetPackages`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getPromocode(model: PromocodeSearchModel){
    this.postData = JSON.stringify(model);
    //debugger;
    return this.http.post<any>(`${ this.adminService }GetPromocode`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
 
  }

  getSurveyCategory(): Observable<any>{
    this.webApiUrl = `${this.adminService}GetSurveyCategory`; 

    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getPackageDetailsById(id: number): Observable<any>{
    this.webApiUrl = `${this.adminService}GetPackageDetailsById?id=` + id; 

    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  addEditPackage(model: MedicinePackageModel) {
    this.postData = JSON.stringify(model);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }AddEditPackage`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  addEditPromocode(model: PromocodePackageModel) {
    this.postData = JSON.stringify(model);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }AddEditPromocode`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  deletePackageById(id: number): Observable<any>{
    this.webApiUrl = `${this.adminService}DeletePackageById?id=` + id; 

    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }
  getDeliveryAgencies(): Observable<any>{
    this.webApiUrl = `${this.adminService}GetDeliveryAgencies`; 

    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }
  

  getTableDetailsByName (tableName : string) {
    this.postData = tableName;
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }GetMasterTableByName?tableName=` + this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  insertUpdateMasterTableDetails (MasterTableModel : any) {
    this.postData = JSON.stringify(MasterTableModel);
    console.log('At Service Call (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }InsertUpdateMasterTableDetails`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  GetPatientDetails(searchString:string){
    //return this.http.get<any>(`${ this.adminService }GetPatientDetails`, { headers: this.headers}).pipe(catchError(this.errorHandler));
    this.postData = searchString;
    
    return this.http.post<any>(`${ this.adminService }GetPatientDetails?searchString=` + this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  UpdatePatientDetails (patientDetailsModel) {
    //debugger;//
    this.postData = JSON.stringify(patientDetailsModel);
    //console.log('At Service Call (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }UpdatePatientDetails`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  UpdatePatientDeliveryAddressDetails (patientDeliveryAddressModel) {
    //debugger;//
    this.postData = JSON.stringify(patientDeliveryAddressModel);
    console.log('At Service Call Delivery Address Details (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }UpdatePatientDeliveryAddressDetails`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  // GetOnlyPatientDetails(searchString:string){
  //   //return this.http.get<any>(`${ this.adminService }GetPatientDetails`, { headers: this.headers}).pipe(catchError(this.errorHandler));
  //   this.postData = searchString;
    
  //   return this.http.post<any>(`${ this.adminService }GetPatientDetails?searchString=` + this.postData,
  //   { headers: this.headers}).pipe(catchError(this.errorHandler));
  // }

  assignToPharmacy(model: AssignToPharmaModel) {
    this.postData = JSON.stringify(model);
    //console.log('At Service Call (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }AssignToPharmacy`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getUserRole(){
    return this.http.get<any>(`${ this.adminService }GetLoginUserTypes`,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getUsersPermission(id: string): Observable<UserMenues[]> {
    return this.http.get<any>(`${ this.adminService }GetSelectedMenuesByUserType?id=` + id,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }  

  updateUsersPermission(usermenu: UserMenues): Observable<UserMenues> {  
    this.postData = JSON.stringify(usermenu);
    //console.log('At Service Call (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }MapMenuAccessToUser`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler)); 
  }  

  updateUserMap(usermap: any): Observable<UserMap[]> {  
    this.postData = JSON.stringify(usermap);
    //console.log('At Service Call (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }MapMenuAccessToUser`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }
  
  getSelectedPackageDetails(submissionId: number){
    return this.http.get<any>(`${ this.adminService }GetSelectedPackageDetails?submissionId=` + submissionId,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getQuestionAnswerBySubmissionId(submissionId: number){
    return this.http.get<any>(`${ this.adminService }GetQuestionAnswerBySubmissionId?submissionId=` + submissionId,
    { headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  updateSubmissionPrescriptionDetails(model: SubmissionOperationModel) {  
    this.postData = JSON.stringify(model);
    //console.log('At Service Call (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }UpdateSubmissionPrescriptionDetails`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler)); 
  }  

  getUpdatedSubmissionDetailsBySubmissionId(submissionId){
    this.webApiUrl = `${this.adminService}GetUpdatedSubmissionDetailsBySubmissionId?submissionId=` + submissionId; 
    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  getSubmissionCount(model: SubmissionCountSearch) {  
    this.postData = JSON.stringify(model);
    //console.log('At Service Call (Login Model) : ' + this.postData);
    return this.http.post<any>(`${ this.adminService }GetSubmissionCount`, this.postData,
    { headers: this.headers}).pipe(catchError(this.errorHandler)); 
  }

  getMenuDetailsByUserAccessLevel(userAccessLevelId){
    this.webApiUrl = `${this.adminService}GetMenuDetailsByUserAccessLevel?userAccessLevelId=` + userAccessLevelId; 
    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }

  checkNonRefundedAccountByPatientEmail(email) {
    this.webApiUrl = `${this.adminService}CheckNonRefundedAccountByPatientEmail?email=` + email; 
    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }
  getPremiumSubscriberList(model: PremiumUserSearchModel) {
    debugger;
    this.postData = JSON.stringify(model);
    //console.log("Withing the Delivery Note : " + this.postData);
    return this.http.post<any>(`${ this.adminService }GetPremiumSubscriberList`, this.postData, { headers: this.headers}).pipe(catchError(this.errorHandler));
  }
  getPremiumSubscribersByEmail(email) {
    this.webApiUrl = `${this.adminService}GetPremiumSubscribersByEmail?email=` + email; 
    return this.http.get<any>(this.webApiUrl,{ headers: this.headers}).pipe(catchError(this.errorHandler));
  }
}
