import { DatePipe, LocationStrategy } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/admin/dialog/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/admin/dialog/confirm-dialog/confirm-dialog.component';
import { SharedComponentService } from 'src/app/api-services/shared-component-services/shared-component.service';
import { SurveyServicesService } from 'src/app/api-services/survey-services/survey-services.service';
import { AcuteCareModel } from 'src/app/Models/Acute-Care';
import { SurveyDetails, SurveyStep, SurveySubmissionDetails, IntakeFormLogModel, ChildDisplay } from 'src/app/Models/survey-details';
import { AdminServicesService } from '../../api-services/admin-services/admin-services.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {

  @ViewChild('scrollPanel') public panel:ElementRef;

  surveySteps: SurveyStep[];
  currentStep: SurveyStep = {
    questions: [],
    stepCode: 0,
    stepID: 0,
    subTitle: "",
    tabOrder: 0,
    title: ""
  };

  acuteCareModel: AcuteCareModel;
  
  stepCounter: number = 0;
  maxStepIndex: number;
  @ViewChild('form') form: any;
  @ViewChild('myElement') desc: any;
  formCompleted: boolean = false;
  surveySubmissionDetails: SurveySubmissionDetails;
  selectedSurveyId: number;
  countForEmail: number = 1;
  emailValue: string;
  phoneValue: string;

  isDisplayPreviousButton: boolean = false;
  isDisplayNextButton: boolean = true;
  answers: { [id: number]: string; } = {};
  descAnswer: {[desc: string]: string} = {}

  isNextButtonDisabled: boolean = false;
  selectedStateValue: string = "";
  formDataCaptureString: string = "";

  childDisplayArry: ChildDisplay[];

  constructor(
    private surveyServices: SurveyServicesService, 
    public adminService: AdminServicesService,
    private sharedService: SharedComponentService, 
    private router: Router, 
    private dialog: MatDialog,
    private el: ElementRef, 
    private location: LocationStrategy) {
      history.pushState(null, null, window.location.href);
      this.location.onPopState(() => {  
        history.pushState(null, null, window.location.href);
        });
     }

  ngOnInit(): void {
    debugger;
    let selectedSurvey: SurveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
    this.selectedSurveyId = (selectedSurvey === undefined) ? 0 : selectedSurvey.surveyId;
    this.surveyServices.getQuestionBySurveyDetailsId(this.selectedSurveyId).subscribe(response => {
      if (response && response.data) {
        this.surveySteps = response.data;
        this.maxStepIndex = (this.surveySteps.length - 1);
        this.currentStep = this.surveySteps[this.stepCounter];
        let fetchedAns: { [id: number]: string; } = JSON.parse(sessionStorage.getItem("patientAns"));
        
        if(fetchedAns) {
          this.answers = fetchedAns;
          this.stepCounter = this.surveySteps.length - 1;
          this.currentStep = this.surveySteps[this.stepCounter];
          this.formCompleted = true;
          this.isDisplayPreviousButton = true;
        }
      }
      else {
        //implement any error prompt
      }
    });
  }

  previousClick() {
    this.stepCounter = this.stepCounter - 1;
    this.currentStep = this.surveySteps[this.stepCounter];
    this.checkPreviousShow(this.stepCounter);
    this.checkNextButtonShow(this.stepCounter);
  }

  nextClick() {
    let serveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
    
    this.formDataCaptureString = "";

    this.formDataCaptureString = this.formDataCaptureString + JSON.stringify(this.answers);
console.log('this.formDataCaptureString1'+this.formDataCaptureString);
    Object.keys(this.form.controls).forEach(key => {
      if (this.countForEmail == 12) {
        //console.log(this.form.controls[key]);
        this.emailValue = this.form.controls[key].value;
      }
      if (this.countForEmail == 10) {
        this.phoneValue = this.form.controls[key].value;
      }
      this.form.controls[key].markAsTouched();

      this.countForEmail++;
    });
    if (this.form.valid) {
      debugger;
      if (Number(serveyDetails.surveyId) != 2 && Number(serveyDetails.surveyId) != 7 && Number(serveyDetails.surveyId) != 10 && Number(serveyDetails.surveyId) != 13) {
        if (this.stepCounter == 0) {
          this.adminService.checkNonRefundedAccountByPatientEmail(this.emailValue).subscribe(res=> {
            if(res && res.data) {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                  message: "Your email address has been blocked due to refund policy.",
                  buttonText: {
                    ok: 'OK',
                    cancel: ''
                  }
                }
              });

              dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                this.router.navigate(['/intakeform/selector']);
              });
            }
            else {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                  message: "Please confirm you have entered correct mobile number (Not a landline number).",
                  buttonText: {
                    ok: 'I confirm my mobile number',
                    cancel: 'Cancel'
                  }
                }
              });
    
              dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                if (confirmed) {
                  const a = document.createElement('a');
                  a.click();
                  a.remove();
                  console.log(this.stepCounter);
  
                  this.proceedToNext();
    
    
                  //Entry to log
                  var logGuid = localStorage.getItem("logGuid");
                  if (logGuid == null || logGuid == '')
                    logGuid = '';
                  else
                    localStorage.removeItem("logGuid");
    
                  var logModel: IntakeFormLogModel = {
                    logConnId: logGuid,
                    userPhone: this.phoneValue,
                    userEmail: this.emailValue,
                    userAction: serveyDetails.surveyTypeName + "->" + serveyDetails.surveyName + "-> Clicked On Next with step counter -> " + this.stepCounter,
                    userActionCode: 200,
                    ipAddress: localStorage.getItem("clientIp"),
                    browserName: this.adminService.detectBrowserName(),
                    browserVersion: this.adminService.detectBrowserVersion(),
                    clientMetaData: this.adminService.detectClientDeviceMetadata(),
                    formData: "Steps: " + this.formDataCaptureString,
                  }
                  this.insertIntakeFormLog(logModel);
    
    
                }
              });
            }
          });
          
        }
        else {
          //console.log(this.stepCounter);
          this.proceedToNext();


          //Entry to log
          var logGuid = localStorage.getItem("logGuid");
          if (logGuid == null || logGuid == '')
            logGuid = '';
          else
            localStorage.removeItem("logGuid");

          var logModel: IntakeFormLogModel = {
            logConnId: logGuid,
            userPhone: this.phoneValue,
            userEmail: this.emailValue,
            userAction: serveyDetails.surveyTypeName + "->" + serveyDetails.surveyName + "-> Clicked On Next with step counter -> " + this.stepCounter,
            userActionCode: 200,
            ipAddress: localStorage.getItem("clientIp"),
            browserName: this.adminService.detectBrowserName(),
            browserVersion: this.adminService.detectBrowserVersion(),
            clientMetaData: this.adminService.detectClientDeviceMetadata(),
            formData: "Steps: " + this.formDataCaptureString,
          }
          this.insertIntakeFormLog(logModel);
        }
      }
      else {


        //Entry to log
        var logGuid = localStorage.getItem("logGuid");
        if (logGuid == null || logGuid == '')
          logGuid = '';
        else
          localStorage.removeItem("logGuid");

        if (this.emailValue == null || this.emailValue == undefined ||
          this.emailValue.toUpperCase() == "NO" || this.emailValue.toUpperCase() == "YES") //This is for COVID Treatment with Symptoms Already registered patient - Here options will have yes / no values only 
          this.emailValue = localStorage.getItem("intakeFormEmail");

        var logModel: IntakeFormLogModel = {
          logConnId: logGuid,
          userPhone: '', //Phone no. could not be fetched
          userEmail: this.emailValue,
          userAction: serveyDetails.surveyTypeName + "->" + serveyDetails.surveyName + "-> Clicked On Next with step counter -> " + this.stepCounter,
          userActionCode: 200,
          ipAddress: localStorage.getItem("clientIp"),
          browserName: this.adminService.detectBrowserName(),
          browserVersion: this.adminService.detectBrowserVersion(),
          clientMetaData: this.adminService.detectClientDeviceMetadata(),
          formData: "Steps: " + this.formDataCaptureString,
        }
        this.insertIntakeFormLog(logModel);

        console.log(this.stepCounter);
        this.proceedToNext();

      }

    }
    else {
      setTimeout(() => {
        window.scroll({ top: (window.innerHeight/2.6) });
        
    });
    alert("please fill up all the required field.");
    }
  }

  proceedToNext() {
    this.stepCounter = this.stepCounter + 1;
    this.currentStep = this.surveySteps[this.stepCounter];

    this.checkPreviousShow(this.stepCounter);
    this.checkNextButtonShow(this.stepCounter);
    window.scroll(0,0);
  }

  checkPreviousShow(stepCounter) {
    if (stepCounter > 0) {
      this.isDisplayPreviousButton = true;
    }
    else {
      this.isDisplayPreviousButton = false;
    }
  }
  checkNextButtonShow(stepCounter) {
    if (stepCounter < this.maxStepIndex) {
      this.isDisplayNextButton = true;
    }
    else {
      this.isDisplayNextButton = false;
    }
  }

  previousReviewClick() {
    debugger;
    this.formCompleted = false;
    this.checkNextButtonShow(this.stepCounter);
    
  }

  submitClick() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].markAsTouched();
    });

    let serveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
    if (serveyDetails.surveyId == 2 || serveyDetails.surveyId == 7 || serveyDetails.surveyId == 10) {
      let existingSubmissionId = sessionStorage.getItem("existingSubmissionId");
      console.log('existingSubmissionId:- '+existingSubmissionId);

      this.surveyServices.getExistingSurveyAsnwerBySubmissionId(Number(existingSubmissionId), serveyDetails.surveyId)
        .subscribe(res => {
          if (res.data != null) {
            res.data.forEach(q => {
              this.answers[q.questionId] = q.answer;
            });

          }
        });
    }
    this.surveySteps.forEach(step => {
      if (step.questions) {
        step.questions.forEach(question => {
          if (question.type == 'Date')
            this.answers[question.questionID] = new DatePipe("en-us").transform(this.answers[question.questionID], 'MM/dd/yyyy');
        });
      }
    });

    if (this.form.valid) {
      this.formCompleted = true;
    }
  }

  stepHasAnswers(step) {
    return step.questions.some(q => {
      if (this.answers[q.questionID])
        return true;
    });
  }

  confirmClick() {
    debugger;
    // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //   data: {
    //     message: "We are swamped and recruiting more doctors as fast as we can. We are seeing sick patients first and backed up several days on prevention",
    //     buttonText: {
    //       ok: 'Proceed',
    //       cancel: 'Cancel'
    //     }
    //   }
    // });

    // dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    //   if (confirmed) {
    //     const a = document.createElement('a');
    //     a.click();
    //     a.remove();
    //     console.log(this.stepCounter);
    //     // code removed from here
    //   }
    // });


    this.formDataCaptureString = "";
    
    this.formDataCaptureString = this.formDataCaptureString + JSON.stringify(this.answers);
    console.log('this.formDataCaptureString:-'+this.formDataCaptureString);

    let serveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
        let patId: number = 0;
        if (serveyDetails != null && (serveyDetails.surveyId == 2 || serveyDetails.surveyId == 7 || serveyDetails.surveyId == 10)) {
          let existingPatientId = sessionStorage.getItem("existingPatientId");
          patId = Number(existingPatientId);
        }
        if(serveyDetails.surveyTypeCode == 5) {
          this.acuteCareModel = this.bindAcuteCareModel();
          sessionStorage.setItem("_acuteCare", JSON.stringify(this.acuteCareModel));
          sessionStorage.setItem("_acuteAnswer", JSON.stringify(this.answers));
          sessionStorage.setItem("_acuteAnswerDesc", JSON.stringify(this.descAnswer));
          this.sharedService.setSurveySWubmissionDetails(this.acuteCareModel.surveySubmissionDetails);
          this.router.navigate(['/intakeform/payment']);
        }
        else {
          debugger;
          this.surveyServices.createSurveySubmission(this.answers).subscribe(res => {
            if (res.data > 0) {
              sessionStorage.setItem("patientAns", JSON.stringify(this.answers));
              if(serveyDetails.surveyId == 13) {
                this.surveySubmissionDetails = {
                  submissionId: res.data,
                  patientDetails: {
                    patientId: patId,
                    firstName: this.answers[1],
                    middleName: this.answers[2],
                    lastName: this.answers[3],
                    phoneNo: this.answers[10],
                    email: this.answers[12],
                    address: this.answers[5],
                    state: this.answers[7],
                    city: this.answers[6],
                    zip: this.answers[8],
                    zipCode:  this.answers[8],
                    gendar: this.answers[4],
                    gender: this.answers[4],
                    dob: this.answers[9],
                    height: this.answers[11205],
                    weight: this.answers[11206].toString(),
                  },
                  surveyDetails: JSON.parse(sessionStorage.getItem("selectedSurvey"))
                };
              }
              else {
                this.surveySubmissionDetails = {
                  submissionId: res.data,
                  patientDetails: {
                    patientId: patId,
                    firstName: this.answers[1] || this.answers[11184],
                    middleName: this.answers[2] || null,
                    lastName: this.answers[3] || this.answers[11186],
                    phoneNo: this.answers[10] || this.answers[11188],
                    email: this.answers[12] || this.answers[11183],
                    address: this.answers[5] || this.answers[11190],
                    state: this.answers[7] || this.answers[11189],
                    city: this.answers[6] || this.answers[11181],
                    zip: this.answers[8] || this.answers[11191],
                    zipCode: this.answers[8] || this.answers[11191],
                    gendar: this.answers[4] || this.answers[11185],
                    gender: this.answers[4] || this.answers[11185],
                    dob: this.answers[9] || this.answers[11182],
                    height: this.answers[15] || null,
                    weight: this.answers[16] ? this.answers[16].toString() : null
                  },
                  surveyDetails: JSON.parse(sessionStorage.getItem("selectedSurvey"))
                };
              }
              
              console.log(this.surveySubmissionDetails);
              this.sharedService.setSurveySWubmissionDetails(this.surveySubmissionDetails);
              sessionStorage.setItem("_criticalDataForPaymentPage", JSON.stringify(this.surveySubmissionDetails));
              //Entry to log
              var logGuid = localStorage.getItem("logGuid");
              if (logGuid == null || logGuid == '')
                logGuid = '';
              else
                localStorage.removeItem("logGuid");
      
              var logModel: IntakeFormLogModel = {
                logConnId: logGuid,
                userPhone: this.surveySubmissionDetails.patientDetails.phoneNo,
                userEmail: this.surveySubmissionDetails.patientDetails.email, //serveyDetails.email,  
                userAction: serveyDetails.surveyTypeName + "->" + serveyDetails.surveyName + "-> Clicked to the payment page.",
                userActionCode: 200,
                ipAddress: localStorage.getItem("clientIp"),
                browserName: this.adminService.detectBrowserName(),
                browserVersion: this.adminService.detectBrowserVersion(),
                clientMetaData: this.adminService.detectClientDeviceMetadata(),
                formData: "Steps: " + this.formDataCaptureString,
              }
              this.insertIntakeFormLog(logModel);
      
      
      
              this.router.navigate(['/intakeform/payment']);
            }
          });
        }

    

  }

  openAlertDialog(alertMessage: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: alertMessage,
        buttonText: {
          cancel: 'OK'
        }
      },
    });
  }



  insertIntakeFormLog(logModel: IntakeFormLogModel) {
    this.adminService.InsertIntakeFormLog(logModel).subscribe(data => {
      if (data.error == null) {
        localStorage.setItem('logGuid', JSON.stringify(data.data));

      }
      else {
        //error in log
      }
    },
      //error => {

      //}
    );
  }

  checkDropdownSelectChange(question, event) {
    let isArray = event.value instanceof Array;
    if(isArray) {
      question.options.forEach(f => {
        if(event.value.indexOf(f.optionValue) != -1) {
          if(f.isChildShow) {
            question.initialView = true;
          }
          else {
            question.initialView = false;
          }
          if(f.isStopper) {
            this.showStopper(event);
          }
        }
      });
    }
    else {
      let selectOptionDetails = question.options.filter(f=>f.optionValue == event.value);
      console.log(event);
      if(selectOptionDetails[0].isChildShow) {
        question.initialView = true;
      }
      else {
        question.initialView = false;
      }

      if(selectOptionDetails[0].isStopper) {
        this.showStopper(event);
      }
    }
    // this.selectedStateValue = value;
    // if (questionId == 7) {
    //   if (value == "Colorado") {
    //     this.isNextButtonDisabled = true;
    //     this.openAlertDialog("Service not available for this area");
    //   }
    //   else {
    //     if (this.selectedStateValue == "Colorado") {
    //       this.isNextButtonDisabled = true;
    //     }
    //     else {
    //       this.isNextButtonDisabled = false;
    //     }

    //   }

    // }
    // else {

    // }
  }
  radioChange(question, event) {
    let selectOptionDetails = question.options.filter(f=>f.optionValue == event.value);
    if(selectOptionDetails[0].isChildShow) {
      question.initialView = true;
    }
    else {
      question.initialView = false;
    }

    if(selectOptionDetails[0].isStopper) {
      this.showStopper(event);
    }
  }

  showStopper(event) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: "For your safety, we advise you to seek your primary care doctor.",
        buttonText: {
          ok: 'OK',
          cancel: ''
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.router.navigate(['/acute-care']);
      }
      else {
        event.value = "";
      }
    });
  }

  bindAcuteCareModel(): AcuteCareModel {
    let acuteCare: AcuteCareModel;
    acuteCare = {
      pharmacyDetails: {
        pharmacyFax: "FAX",
        pharmacyName: "TestPharmacy",
        pharmacyPhone: "Test Phone"
      },
      surveySubmissionDetails: {
        submissionId: 0,
        patientDetails: {
          patientId: 0,
          firstName: this.answers[1],
          middleName: this.answers[2],
          lastName: this.answers[3],
          phoneNo: this.answers[10],
          email: this.answers[12],
          address: this.answers[5],
          state: this.answers[7],
          city: this.answers[6],
          zip: this.answers[8],
          zipCode: this.answers[8],
          gendar: this.answers[4],
          gender: this.answers[4],
          dob: this.answers[9],
          height: '',
          weight: '',
        },
        surveyDetails: JSON.parse(sessionStorage.getItem("selectedSurvey"))
      },
      surveyPaymentDetails: null,
      questionierDetails: null
    }
    return acuteCare;
  }

  LoadConsent() {
    // this.http.get('https://kissht.com/',{responseType:'text'}).subscribe(res=>{
    //   this.faqHtml = this.sanitizer.bypassSecurityTrustHtml(res);
    window.open("https://telehealth.drstellamd.com/assets/Informed_Consent.html", "_blank");
    //});
  }

}
