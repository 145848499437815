<div class="wrapper">
  <mat-card class="container" *ngIf="beforePaymentSection">
      <h3 class="text-center" style="color: #4043ac;">Welcome to Telehealth Services </h3>
      <h3 class="text-center" style="color: #4043ac;">with Dr Stella Immanuel MD </h3>
      <!-- <p class="text-center" style="margin-bottom: 0;">$90 for prevention for Covid-19</p>
      <p class="text-center">$185 for sick patients! Includes up to 3 follow up visits</p> -->
      <!-- <ng-container *ngIf="currentStep">
          <mat-card-title>Step {{ (stepCounter + 1) }} {{ currentStep.title }}</mat-card-title>
          <mat-card-subtitle>{{ currentStep.subTitle }}</mat-card-subtitle>
      </ng-container> -->
  <mat-card-content>
    <div class="row">
      
    </div>
    <div id ="header_text" *ngIf="!isPremium">
    <!-- <p class="text-center" style="margin-bottom: 0; color:red"><b>After Successful payment, you will be routed to a Schedular</b></p>
    <p class="text-center" style="margin-bottom: 0; color:red"><b>As a next step, please ensure that you schedule a consultation with our provider.</b></p> -->
    </div>
    <div class="row Procedure_Charges">
      <div class="col-md-3">
        <mat-card>
          <mat-card-title>
            {{procedureText}}:
            ${{ procedureCharge }}
          </mat-card-title>
        </mat-card>
      </div>
    </div>
    <div class="row">
      <form [formGroup]="paymentForm" (ngSubmit)="submitPaymentForm()" style="width: 100%;">
        <mat-card>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="standard">
                  <mat-label>Card Holder Name<span style="color: red;">*</span></mat-label>
                  <input matInput placeholder="Enter Card holder name..."
                         formControlName="CardHolderName">
                  <mat-error *ngIf="checkError('CardHolderName', 'required')">
                    Name is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="standard">
                  <mat-label>Card Number<span style="color: red;">*</span></mat-label>
                  <input matInput (keydown)="disallowSpace($event)"
                         placeholder="Enter Card number..." formControlName="CardNumber">
                  <mat-error *ngIf="checkError('CardNumber', 'required')">
                    Card number is required
                  </mat-error>
                  <mat-error *ngIf="checkError('CardNumber', 'pattern')">
                    only numerical digits allowed
                  </mat-error>
                  <mat-error *ngIf="checkError('CardNumber', 'maxlength')">
                    Card number length should be equals to 16
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <mat-form-field appearance="standard">
                  <mat-label>Expiry Month<span style="color: red;">*</span></mat-label>
                  <input matInput placeholder="MM" formControlName="ExpiryMonth">
                  <mat-error *ngIf="checkError('ExpiryMonth', 'required')">
                    Expiry month is required
                  </mat-error>
                  <mat-error *ngIf="checkError('ExpiryMonth', 'pattern')">
                    only numerical digits allowed
                  </mat-error>
                  <mat-error *ngIf="checkError('ExpiryMonth', 'maxlength')">
                    Expiry month length should be equals to 2
                  </mat-error>
                  <mat-error *ngIf="checkError('ExpiryMonth', 'minlength')">
                    Expiry month length should be equals to 2
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="standard">
                  <mat-label>Expiry Year<span style="color: red;">*</span></mat-label>
                  <input matInput placeholder="YYYY" formControlName="ExpiryYear">
                  <mat-error *ngIf="checkError('ExpiryYear', 'required')">
                    Expiry year is required
                  </mat-error>
                  <mat-error *ngIf="checkError('ExpiryYear', 'pattern')">
                    only numerical digits allowed
                  </mat-error>
                  <mat-error *ngIf="checkError('ExpiryYear', 'minlength')">
                    Expiry Year length should be equals to four
                  </mat-error>
                  <mat-error *ngIf="checkError('ExpiryYear', 'maxlength')">
                    Expiry Year length should be equals to four
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field appearance="standard">
                  <mat-label>CVV<span style="color: red;">*</span></mat-label>
                  <input matInput placeholder="CVV" formControlName="cvc">
                  <mat-error *ngIf="checkError('cvc', 'required')">
                    CVV is required
                  </mat-error>
                  <mat-error *ngIf="checkError('cvc', 'pattern')">
                    only numerical digits allowed
                  </mat-error>
                  <mat-error *ngIf="checkError('cvc', 'maxlength')">
                    CVV length should be less than or equals to 3.
                  </mat-error>
                </mat-form-field>
              </div>              
            </div>
            
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6">
                    <mat-form-field appearance="standard">
                      <mat-label>Amount($)</mat-label>
                      <input matInput class="mat-form-field-readonly" formControlName="Amount"
                             autocomplete="off" readonly="readonly">
                    </mat-form-field>
                  </div>                 
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2" style="margin-top: 25px;">
                <button mat-raised-button color="primary" type="submit"
                        [disabled]="isSubmitInProgress">
                  <mat-icon *ngIf="isSubmitInProgress">
                    <mat-spinner diameter="20">
                    </mat-spinner>
                  </mat-icon>
                  Make Payment
                </button>
              </div>
              <div class="col-md-5" style="margin-top: 25px">
                <strong class="blink_text">
                  ** Please be careful when making a payment. There are no refunds for payments once they have been processed.
                </strong>
              </div>
              <div class="col-md-5" style="margin-top: 25px" *ngIf="isSubmitInProgress">
                <strong class="blink_text">
                  ** Please don't refresh the page until payment has been
                  done
                </strong>
              </div>
            </div>

          </div>
        </mat-card>

      </form>

      <div class="row Procedure_Charges" *ngIf="isAddressFound">
        <div class="col-md-4">
          <mat-card>
            <mat-card-title>
              <div class="row">
                <div class="col-md-8">
                  <h2>Delivery Address</h2>
                </div>
                <div class="col-md-4">
                  <button mat-icon-button (click)="openEditAddressForm(element)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
              </div>
              <div>
                {{patientSurveyModel.firstName}}&nbsp;{{patientSurveyModel.lastName}}<br />
                {{patientSurveyModel.address}}<br />
                {{patientSurveyModel.city}}<br />
                {{patientSurveyModel.state}}<br />
                {{patientSurveyModel.zip}}
              </div>
              <!-- <div *ngIf="isNoAddressFound">
              No address for the Patient present. Kindly update the Address.
          </div> -->
            </mat-card-title>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-card-content>
      <mat-card-footer>
          <div class="row">
              <h5 style="color: red;">
                  <strong class="blink_text">In case of difficulty in filling up the form, please email us at..
                  </strong>
                  <h4>
                      <a style="font-weight: bold; font-size: 18px !important;"
                          href="mailto:patienthelpdesk@drstellamd.com">patienthelpdesk&#64;drstellamd.com</a>
                          <br/>
                          <a style="font-weight: bold; font-size: 18px !important;" href="tel:+832-808-5574">Telephone - 832-808-5574</a>
                  </h4>
              </h5>
          </div>
          <div class="row">
              <div class="col-sm-6 align-text-bottom"><img src="assets/img/drstella_logo.png" height="50px"></div>
              <div class="col-sm-6 text-right poweredby align-text-bottom" *ngIf="false">Powered by :<a
                      href="https://www.gracesoft.com" target="_blank"><img src="assets/img/gracesoft_logo.png"
                          height="70px"></a></div>
          </div>
      </mat-card-footer>
  </mat-card>
</div>
