import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ServiceSubscription, SubcribeDetailModel, SurveyMedicine, SurveySubmissionDetails } from 'src/app/Models/survey-details';
import { SurveyServicesService } from 'src/app/api-services/survey-services/survey-services.service';
import { SurveyDetails, SurveyStep, IntakeFormLogModel, ChildDisplay } from 'src/app/Models/survey-details';
import { SharedComponentService } from 'src/app/api-services/shared-component-services/shared-component.service';
import { PremiumSubscriptionOtpResponseModel } from 'src/app/Models/premium-subscription-otp-response-model.model';
import { numericValidator } from 'src/app/app.component';

@Component({
  selector: 'app-subscribefreeservices',
  templateUrl: './subscribefreeservices.component.html',
  styleUrls: ['./subscribefreeservices.component.sass']
})

export class SubscribefreeservicesComponent implements OnInit  {
 
  formGroupProductCheckOutAddress: FormGroup = new FormGroup({});   //This form group will capture all the data for address
  
  isBillingDetailsReadonly: boolean = false;
  
  productList: ServiceSubscription[] = [];
  subscriptionModel: SubcribeDetailModel= {} as SubcribeDetailModel;
  shipmentCharge: number = 0;
  isPromocodeButtonDisabled: boolean = true;
  discountedPercent: number = 0.00;
  discountedPrice: number = 0.00;
  promoCodeInvalidLabel: string = "";
  
  isCartEmpty: boolean = false;
  totalPriceWithShippingAndDiscount: number = 0.00;
  discountAmountShow: string = "";
  isAutoshipProductPayment: boolean = false;
  isNormalProductPayment: boolean = false;

  supplementProductAmount: number = 0;

  isPaymentConditionSelected: boolean = false;
  isSubscriptionRelatedProductPresent: boolean = false;

  //This click event subcription will receive event message / trigger when "check out" clicked at the header component
  clickEventsubscription_UpdateProductCheckOutAmountValue: ServiceSubscription;
  surveySubmissionDetails: SurveySubmissionDetails;
  SubcribeDetailModel: {
    Action: number; ServiceTypeId: any; FirstName: any; LastName: any; Email: any; PhoneNo: any; ShippingAddress1: any; ShippingAddress2: any; ShippingCity: any; ShippingState: any; ShippingZipCode: any; DeliveryAddress1: any; DeliveryAddress2: string; // Assuming it's always empty
    DeliveryCity: any; DeliveryState: any; DeliveryZipCode: any; DeliveryAgencyId: number; // Default value or fetch from a source
    DeliveryAgencyName: string; // Default value or fetch from a source
    SubscriptionCheck: boolean; // Default value or fetch from a source
    UerPaymentCount: number; // Default value or fetch from a source
    FindAboutUs: string;
  };
  surveyMedicines: SurveyMedicine[] = [];
  isOTPVisible: boolean = false;
  isOTPValid: boolean = false;
  isOtpMessage: boolean = false;
  isMakeEmailReadonly: boolean = false;
  otpReceived: string = "";
  emailMessage: string = "";
  isOtpMismatch: boolean = false;
  otpMismatchMessage: string = "";
  isOtherSelected: boolean = false;
  @ViewChild('otpDialog') otpDialog!: TemplateRef<any>;  // Reference to OTP dialog template
  @ViewChild('TnCDialog') TnCDialog!: TemplateRef<any>;
  currentSnackBarRef: any;
  private dialogRef: MatDialogRef<any> | null = null;
  submitted:boolean = false;
  otpReceivedTime: Date=null;
  discount: number = 0;
  promoError: string = '';
  states: string[] = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 
    'Delaware', 'District of Columbia','Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 
    'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 
    'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 
    'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 
    'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'Washington DC', 'West Virginia', 'Wisconsin', 'Wyoming'
];
  constructor(
    private fBuilder: FormBuilder
    , private router: Router
    , public datepipe: DatePipe
    , private dialog: MatDialog
    , private _snackBar: MatSnackBar
    ,private route: ActivatedRoute
    ,private surveyServices: SurveyServicesService
    ,private sharedService: SharedComponentService
  ) {
    //This will restrict only Cart Check-Out Navigation to Payment CheckOut Page
    this.SetupInitialParameters();    
  }

  GetOtp() {
  // Check if the form is valid except the OTP field
  const requiredFields = [
    'ShippingDetailsEmail',
    'ShippingDetailsFirstName',
    'ShippingDetailsLastName',
    'ShippingDetailsPhoneNo',
    'ShippingDetailsShippingAddress1',
    'ShippingDetailsShippingCity',
    'ShippingDetailsShippingState',
    'ShippingDetailsZipcode',
    'BillingDetailsBillingAddress',
    'BillingDetailsCity',
    'BillingDetailsState',
    'BillingDetailsZipcode',
    'FindAboutUs',
    'declaration',
    'acceptance',
  ];
  this.submitted = true;
  this.otpReceivedTime = new Date();
  // Check if any required field is missing or invalid
  const isFormValid = requiredFields.every(field => {
    const control = this.formGroupProductCheckOutAddress.get(field);
    return control && control.valid;
  });

  if (isFormValid) {
    const email: string = this.formGroupProductCheckOutAddress.value["ShippingDetailsEmail"];
    const firstName: string = this.formGroupProductCheckOutAddress.value["ShippingDetailsFirstName"];
    if (email) {
      this.surveyServices.getsubscriberService(email).subscribe(res => {
        if(res.data) {
          this.openSnackBar(res.data[0].email + " Already Registered" , "Dismiss");         
        }
        else{
          this.surveyServices.getPremiumLoginOTP(firstName,email).subscribe(s => {
          let result = s.data;
          if (s.Error == null && result!=0) {
            this.isOtpMessage = true;
            this.otpReceived = result;
            this.openOtpDialog(); // Open OTP dialog only if OTP is received
            this.openSnackBar("One Time Password sent to your email.", "Dismiss");
          }
          else{
            this.openSnackBar("something is wrong..", "Dismiss");
          }
        }); 
        }
      }, 
      );     
      
    } else {
      this.openSnackBar("Email is Required.", "Dismiss");
    }
   }
 
}

  // Method to open OTP dialog
  openOtpDialog() {
    // If the dialog is already open, close it first
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  
    // Open the new OTP dialog and set the reference
    this.dialogRef = this.dialog.open(this.otpDialog, {
      disableClose: true,  // Prevent closing the dialog by clicking outside
      width: '400px',      // Optional: Set width for better control
      panelClass: 'custom-otp-dialog' // Optional: Custom styling class
    });
  }
  
  onOtpDialogClose() {
    // Check if the dialog is open before closing
    if (this.dialogRef) {
      this.dialogRef.close();  // Close the dialog
      this.dialogRef = null;   // Clear the reference after closing
    }
  }
  
  // Method to submit OTP
  SubmitOtp() {
    const otpValue = this.formGroupProductCheckOutAddress.value["OTP"];
  
    // Check if OTP is provided
    if (otpValue) {
      const currentTime = new Date();  // Get current time
      const otpValidDuration = 5 * 60 * 1000; // 5 minutes in milliseconds
      const timeElapsed = currentTime.getTime() - this.otpReceivedTime.getTime(); // Calculate time elapsed since OTP was received
  
      if (timeElapsed <= otpValidDuration) { // Check if OTP is still valid (within 5 minutes)
        if (this.otpReceived === otpValue) {
          this.MakeSubscription();  // Proceed to subscription if OTP matches
          this.onOtpDialogClose();  // Close dialog after submission
        } else {
          this.isOtpMismatch = true;
          this.openSnackBar("One Time Password is not correct.", "Dismiss");
        }
      } else {
        this.openSnackBar("One Time Password has expired. Please request a new OTP.", "Dismiss");
      }
    } else {
      this.openSnackBar("One Time Password is required.", "Dismiss");
    }
  }
  
  //Terms and condition dialog
  // Method to open OTP dialog
  openTnCDialog() {
    // If the dialog is already open, close it first
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  
    // Open the new OTP dialog and set the reference
    this.dialogRef = this.dialog.open(this.TnCDialog, {
      disableClose: true,  // Prevent closing the dialog by clicking outside
      width: '400px',      // Optional: Set width for better control
      panelClass: 'custom-otp-dialog' // Optional: Custom styling class
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });   
  }

  SetupInitialParameters() {
    let currentUrl: string = window.location.href;
    //This will setup form controls
    this.SetupFormControls();
    localStorage.setItem('PremiumPrice', '199.00');  
    }

  SetupFormControls() {
    this.formGroupProductCheckOutAddress
      = this.fBuilder.group({
       
        ShippingDetailsFirstName: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        ShippingDetailsLastName: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        ShippingDetailsEmail: new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.maxLength(100),
        ]),
        ShippingDetailsPhoneNo: [
          '',
          [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]*$')],
        ],
        ShippingDetailsShippingAddress1: new FormControl('', [
          Validators.required,
          Validators.maxLength(200),
        ]),
        ShippingDetailsShippingAddress2: new FormControl('', [
          Validators.maxLength(200),
        ]),
        ShippingDetailsShippingCity: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        ShippingDetailsShippingState: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        ShippingDetailsShippingCountry: new FormControl('', [
          Validators.required
        ]),
        ShippingDetailsZipcode: new FormControl('', [
          Validators.required,
          Validators.maxLength(5),
          numericValidator(),
        ]),
        
        BillingDetailIsSameAsShippingAddress: false,

        BillingDetailsBillingAddress: new FormControl('', [
          Validators.required,
          Validators.maxLength(401),
        ]),
        BillingDetailsCity: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        BillingDetailsState: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        BillingDetailsZipcode: new FormControl('', [
          Validators.required,
          Validators.maxLength(10),
        ]),
        FindAboutUs: new FormControl('', [
          Validators.required
        ]),
        OTP: new FormControl(''),
        Promocode: new FormControl(''),
        declaration: new FormControl(false, Validators.requiredTrue),
        OtherDetails: new FormControl(''),
        acceptance: new FormControl(false, Validators.requiredTrue),
      });
  }

  ReturnToSubscription() {
    //If there is any autoship item list then that will be cleared
   
    window.location.href = '/intakeform/Surveysubscription';
  }

  //This will be used when we toggle to duplicate address
  checkToggle() {
    var billingAddress = "";
    var billingCity = "";
    var billingState = "";
    var billingZipCode = "";

    if (this.formGroupProductCheckOutAddress.value["BillingDetailIsSameAsShippingAddress"] == true) {

      //Set Value
      billingAddress = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingAddress1"] + ","
        + this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingAddress2"];
      billingCity = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingCity"];
      billingState = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingState"];
      billingZipCode = this.formGroupProductCheckOutAddress.value["ShippingDetailsZipcode"];

      //Disable Control
      this.isBillingDetailsReadonly = true;
    }
    else {
      //Enable Control
      this.isBillingDetailsReadonly = false;

    }
    this.formGroupProductCheckOutAddress.get('BillingDetailsBillingAddress')?.setValue(billingAddress);
    this.formGroupProductCheckOutAddress.get("BillingDetailsCity")?.setValue(billingCity);
    this.formGroupProductCheckOutAddress.get("BillingDetailsState")?.setValue(billingState);
    this.formGroupProductCheckOutAddress.get("BillingDetailsZipcode")?.setValue(billingZipCode);
  }

  //Send Payload to BE and make payment
  MakeSubscription() {
    // Extract form values
    const firstName = this.formGroupProductCheckOutAddress.value["ShippingDetailsFirstName"];
    const lastName = this.formGroupProductCheckOutAddress.value["ShippingDetailsLastName"];
    const email = this.formGroupProductCheckOutAddress.value["ShippingDetailsEmail"];
    const phoneNo = this.formGroupProductCheckOutAddress.value["ShippingDetailsPhoneNo"];
    const shippingAddress1 = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingAddress1"];
    const shippingAddress2 = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingAddress2"];
    const shippingCity = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingCity"];
    const shippingState = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingState"];
    const shippingZipCode = this.formGroupProductCheckOutAddress.value["ShippingDetailsZipcode"];
    const deliveryAddress1 = this.formGroupProductCheckOutAddress.value["BillingDetailsBillingAddress"];
    const deliveryCity = this.formGroupProductCheckOutAddress.value["BillingDetailsCity"];
    const deliveryState = this.formGroupProductCheckOutAddress.value["BillingDetailsState"];
    const deliveryZipCode = this.formGroupProductCheckOutAddress.value["BillingDetailsZipcode"];
    // Get the selected 'FindAboutUs' value
    let findAboutUs = this.formGroupProductCheckOutAddress.value["FindAboutUs"];
    
    // If "Other" is selected, append the other details input
    if (findAboutUs === 'Other') {
        const otherDetails = this.formGroupProductCheckOutAddress.value["OtherDetails"];
        findAboutUs = `Other: ${otherDetails}`;  // Append the "Other" value
    }
  
    // Iterate over selected service subscription IDs
    this.SubcribeDetailModel = {
        Action: 1,
        ServiceTypeId: 0,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        PhoneNo: phoneNo,
        ShippingAddress1: shippingAddress1,
        ShippingAddress2: shippingAddress2,
        ShippingCity: shippingCity,
        ShippingState: shippingState,
        ShippingZipCode: shippingZipCode,
        DeliveryAddress1: deliveryAddress1,
        DeliveryAddress2: "", // Assuming it's always empty
        DeliveryCity: deliveryCity,
        DeliveryState: deliveryState,
        DeliveryZipCode: deliveryZipCode,
        
        DeliveryAgencyId: 0, // Default value or fetch from a source
        DeliveryAgencyName: '', // Default value or fetch from a source
        SubscriptionCheck: false, // Default value or fetch from a source
        UerPaymentCount: 0, // Default value or fetch from a source
        FindAboutUs:findAboutUs,
      };
      
      debugger;
      this.surveySubmissionDetails = {
        submissionId: 0,
          patientDetails: {
            patientId: 0,
            firstName: this.SubcribeDetailModel.FirstName,
            middleName: '',
            lastName: this.SubcribeDetailModel.LastName,
            phoneNo: this.SubcribeDetailModel.PhoneNo.toString(),
            email: this.SubcribeDetailModel.Email,
            address: this.SubcribeDetailModel.ShippingAddress1,
            state: this.SubcribeDetailModel.ShippingState,
            city: this.SubcribeDetailModel.ShippingCity,
            zip: this.SubcribeDetailModel.ShippingZipCode,
            zipCode: '',
            gendar: '',
            gender: '',
            dob: '',
            height: '',
            weight: '',
            ispremium:true,
            findAboutUs: this.SubcribeDetailModel.FindAboutUs,
          },
          surveyDetails: {
          surveyId: 0,
          surveyCode: 0,
          surveyName: 'Premium Services',
          surveyTypeName: '',
          procedureRate: 199,
          shippingCharge: 0,
          surveyTypeCode: '',
          hasRecordCheck: false,
          description: '',
          service_mode: '',
          created_at: new Date(),
          updated_at: new Date(),
          RefPatientStatus: 0,
          findAboutUs:'',
        }
      }
      debugger;
      
      // this.surveyServices.getsubscriberService(this.surveySubmissionDetails.patientDetails.email).subscribe(res => {
      //   if(res.data && res.data) {
      //   debugger;
      //     this.openSnackBar(res.data[0].email + " Already Registered till " +res.data[0].enrollmentDate , "Dismiss");         
      //   }      
      //   else{
          this.sharedService.setSurveySWubmissionDetails(this.surveySubmissionDetails);
          sessionStorage.setItem("_criticalDataForPaymentPage", JSON.stringify(this.surveySubmissionDetails));
          sessionStorage.setItem("selectedSurvey", JSON.stringify(this.surveySubmissionDetails.surveyDetails));
          this.router.navigate(['/intakeform/payments']);
      //   }
      // }, 
      // );       
  } 

  ProceedToHomePage() {
    window.location.href = this.router['location']._platformLocation.location.origin;
  }

  ProceedToAutoShip() {
    window.location.href = '/autoship-order/';
  }

  DisabledPayNowActions() {
    this.openSnackBar("Please validate all the input data.", "Dismiss");
  }

  openSnackBar(message: string, action: string) {
    // Dismiss the currently open snackbar, if any
    if (this.currentSnackBarRef) {
      this.currentSnackBarRef.dismiss();
    }
    
    // Open a new snackbar and keep a reference to it
    this.currentSnackBarRef = this._snackBar.open(message, action, {
      duration: 15000,
    });
  
    // Clear the reference after it is dismissed
    this.currentSnackBarRef.afterDismissed().subscribe(() => {
      this.currentSnackBarRef = null;
    });
  }
  
  closeSnackBar() {
    this._snackBar.dismiss();
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.formGroupProductCheckOutAddress.controls[controlName].hasError(errorName);
  };

  DisallowSpace(event: any) {
    return event.keyCode != 32 ? event : event.preventDefault();
  }

  onFindAboutUsChange(value: string) {
    this.isOtherSelected = value === 'Other';
    if (!this.isOtherSelected) {
      // Clear the "OtherDetails" field when "Other" is not selected
      this.formGroupProductCheckOutAddress.get('OtherDetails')?.reset();
    }
  }
 
  allowOnlyDigits(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'];
  
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault(); // Prevent the default action if the key is not allowed
    }
  }

  applyPromocode() {
    const promocode = this.formGroupProductCheckOutAddress.value["Promocode"];
    if (!promocode) {
      this.promoError = 'Please enter a promocode.';
      return;
    }
    var originalPrice = 199.00;   
    this.surveyServices
      .getPromoByPromoName(promocode)
      .subscribe((res) => {
        if (res.data) {
          this.discount = 5;
          this.promoError = '';
          
          this.discountedPrice = originalPrice - (originalPrice * this.discount / 100); // Calculate final price after discount
          localStorage.setItem('PremiumPrice', this.discountedPrice.toFixed(2));          
        }
        else{
          debugger;
          this.discount = 0;
          this.promoError = 'Invalid or expired promocode.';
          localStorage.setItem('PremiumPrice', originalPrice.toFixed(2));          
        }
        
      });
    
  }
}
