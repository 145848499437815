import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogModule, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SharedComponentService } from "src/app/api-services/shared-component-services/shared-component.service";
import { SurveyServicesService } from "src/app/api-services/survey-services/survey-services.service";
import {
  CompleteSurveyModel,
  patientSearchModel,
  PrintInvoiceModel,
  PromoDetails,
  SurveyMedicine,
  SurveyPaymentDetails,
  SurveySubmissionDetails,
  SurveyPatientDetails,
  SurveyDetails,
  IntakeFormLogModel,
  InvoicePreviewModel,
} from "src/app/Models/survey-details";
import redirectLink from "../../../assets/data/third-party-link.json"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { PaymentAddressComponent } from "./../payment-address/payment-address.component";
import { TouchSequence } from "selenium-webdriver";
import { AlertDialogComponent } from "src/app/admin/dialog/alert-dialog/alert-dialog.component";
import { AdminServicesService } from "../../api-services/admin-services/admin-services.service";
import { MarketPlaceService } from "src/app/api-services/market-place.service";
import { ConfirmDialogComponent } from "src/app/admin/dialog/confirm-dialog/confirm-dialog.component";
import { PaymentConfirmComponent } from "./../payment/payment-confirm/payment-confirm.component";
import { AcuteCareModel } from "src/app/Models/Acute-Care";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-payment",
  templateUrl: "./premiumpayment.component.html",
  styleUrls: ["./premiumpayment.component.sass"],
})
export class PremiumpaymentComponent implements OnInit {
  isSpinnerWait: Boolean = true;
  surveyMedicines: SurveyMedicine[] = [];
  selectedMedicinePackageId: number;
  pharmacyCharge: number = 0;
  procedureCharge: number = parseFloat(localStorage.getItem('PremiumPrice') || '0');
  surveySubmissionDetails: SurveySubmissionDetails;
  patientSurveyModel: SurveyPatientDetails = {
    patientId: 0, firstName: "", middleName: "", lastName: "", phone: "", email: "", address: "",
    state: "", city: "", zip: "", gender: "", dob: "", height: "", weight: "", ispremium: true,
    enrollmentDate: "01/01/9999",
  };

  promoButtonDisabled: boolean = false;
  promoButtonText: string = "Apply";
  procedureText: string="Premium Subscription Charges";
  beforePaymentSection: boolean = true;
  generatedPaymentId: number;
  invoiceData: PrintInvoiceModel[];
  generationInvoiceHtml: string;
  isSubmitInProgress: boolean = false;
  isDownloadInProgress: boolean = false;
  patientId: number;
  pachageCheckboxName: string = "packageName";
  surveyDetails: SurveyDetails;
  initialLoad: boolean = true;
  isAddressFound: boolean = false;
  ipAddress: string = "";
  browserName: string = "";
  browserVersion: string = "";
  clientMetaData: string = "";
  isPremium: boolean = false;
  formDataCaptureString: string = "";
  findAboutUs:string="";

  @ViewChild("pdfTable", { static: false }) pdfTable: ElementRef;

  paymentForm = new FormGroup({
    CardHolderName: new FormControl("", [Validators.required]),
    CardNumber: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.maxLength(16),
    ]),
    ExpiryMonth: new FormControl("", [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.pattern("^[0-9]*$"),
    ]),
    ExpiryYear: new FormControl("", [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
      Validators.pattern("^[0-9]*$"),
    ]),
    cvc: new FormControl("", [
      Validators.required,
      Validators.maxLength(3),
      Validators.pattern("^[0-9]*$"),
    ]),
    Amount: new FormControl(""),
    PromoCode: new FormControl("")
  });

  constructor(
    private surveyService: SurveyServicesService,
    private marketPlaceService: MarketPlaceService,
    private sharedService: SharedComponentService,
    private surveyServices: SurveyServicesService,
    private dialog: MatDialog,
    public adminService: AdminServicesService,
    private router: Router,
  ) { }

  ngOnInit(): void {   
    this.surveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
    if (this.sharedService.currentSurveySubmissionDetails === undefined) {      
      this.router.navigate(["/intakeform/SubscribeFreeServices"]);
    } else {
      this.initialLoader();
    }   
    
  }
  initialLoader() {  
    
    if (this.sharedService.currentSurveySubmissionDetails) {
      this.sharedService.currentSurveySubmissionDetails.subscribe((data) => {
        debugger;
        if (data) {
          this.surveySubmissionDetails = data; 
          this.patientId = this.surveySubmissionDetails.patientDetails.patientId;          
          this.paymentForm.patchValue({
            Amount: this.procedureCharge.toString()
          });
          this.reloadAddress(); 
        }
      });
    }  
    
      
  }
  reloadAddress() {
    this.surveyServices
      .GetOnlyPatientDetails(this.patientId).subscribe((patientData) => {
        this.isAddressFound = true;
        
        debugger;
        if (patientData.data != null) {

            this.isPremium=patientData.data.isPremium;           
          
          this.patientSurveyModel.patientId = patientData.data.patientId;
          this.patientSurveyModel.firstName = patientData.data.firstName;
          this.patientSurveyModel.lastName = patientData.data.lastName;
          this.patientSurveyModel.phone = patientData.data.phone;
          this.patientSurveyModel.email = patientData.data.email;
          this.patientSurveyModel.address = patientData.data.address;
          this.patientSurveyModel.state = patientData.data.state;
          this.patientSurveyModel.city = patientData.data.city;
          this.patientSurveyModel.zip = patientData.data.zip;
          this.patientSurveyModel.gender = patientData.data.gender;
          this.patientSurveyModel.dob = patientData.data.dob;
          this.patientSurveyModel.height = patientData.data.height;
          this.patientSurveyModel.weight = patientData.data.weight;
          this.patientSurveyModel.ispremium = this.surveySubmissionDetails.patientDetails.ispremium;
          this.patientSurveyModel.enrollmentDate = patientData.data.enrollmentDate;
          this.surveySubmissionDetails.patientDetails.email=patientData.data.email;
          
        } else {
          this.isAddressFound = false;
          this.isPremium=true;
        }
      });
  }

  submitPaymentForm() {
    debugger;
    if (this.paymentForm.valid) {
      var isFromAcuteCare = sessionStorage.getItem("_isAcuteSurvey");
      if (this.isPremium==true) {      
        let invoicePreviewData: InvoicePreviewModel[] = [];    

        
          invoicePreviewData.push({
            name: "Premium charge",
            amount: this.procedureCharge,
            quantity: 1,
            isPromo: false,
          });
       
        const confirmRef = this.dialog.open(PaymentConfirmComponent, { data: invoicePreviewData });
        confirmRef.afterClosed().subscribe((data) => {
          if (data == true) {
            this.makePaymentProcess();
          }
        });
      }
    } else {
      window.scroll({ top: window.innerHeight / 2.6 });
      this.openAlertDialog("please fill up required field.");
    }
  }

  makePaymentProcess() {
    debugger;
    let surveyDetails: SurveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
    this.isSubmitInProgress = true;
  
    let form = this.paymentForm;
    
    if (!this.paymentForm.valid) {
      console.log("Form Errors:", this.paymentForm.errors);
    }

    
    // Construct payment details model
    let paymentDetails: SurveyPaymentDetails = {
      cardHolderName: form.value["CardHolderName"],
      cardNumber: form.value["CardNumber"],
      ExpiryMonth: form.value["ExpiryMonth"],
      ExpiryYear: form.value["ExpiryYear"],
      cvc: form.value["cvc"],
      promoId: 0,
      procedureCharge: this.procedureCharge,
      pharmacyCharge: 0,
      shippingCharge: 0,
      amount: this.procedureCharge,
      amountAfterDeduction: this.procedureCharge,
      medicinePackageIds: [0],
      findAboutUs: this.surveySubmissionDetails.patientDetails.findAboutUs,
      isSubscribedOnMarketPlace: false
    };
  
    // Construct submit model for the API call
    let submitModel: CompleteSurveyModel = {
      surveyPaymentDetails: paymentDetails,
      surveySubmissionDetails: this.surveySubmissionDetails,
    };
  
    console.log("Submit Model:", submitModel); // Log the submitModel for debugging
  
    // Check if session has expired
    if (submitModel.surveySubmissionDetails.patientDetails != null && !submitModel.surveySubmissionDetails.patientDetails.firstName) {
      this.openAlertDialog("Session expired.");
      this.router.navigate(["/intakeform/SubscribeFreeServices"]);
      return;
    }
  
    // Verify form validity
    if (!this.paymentForm.valid) {
      console.log("Form is invalid:", this.paymentForm.errors);
      this.openAlertDialog("Please check the payment form for errors.");
      this.isSubmitInProgress = false;
      return;
    }
  
    // Form data as JSON for logging purposes
    this.formDataCaptureString = "";
    var tempCardJson = '{ "PaymentForm": { "Amount": "' + this.paymentForm.controls.Amount.value + '", '
      + '"CardHolderName": "' + this.paymentForm.controls.CardHolderName.value + '", '
      + '"CardNumber": "' + this.paymentForm.controls.CardNumber.value + '", '
      + '"ExpiryMonth": "' + this.paymentForm.controls.ExpiryMonth.value + '", '
      + '"ExpiryYear": "' + this.paymentForm.controls.ExpiryYear.value + '", '
      + '"PromoCode": "' + this.paymentForm.controls.PromoCode.value + '" },';
  
    tempCardJson += ' "SurveyDetails" : ' + JSON.stringify(this.surveyDetails) + ',';
    tempCardJson += ' "SurveyMedicinesList" : ' + JSON.stringify(this.surveyMedicines) + '}';
  
    this.formDataCaptureString += tempCardJson;
  
    // Make API call to complete the survey process
    this.surveyService.completeSurveyProcess(submitModel).subscribe(
      (res) => {
        if (res.data != null && res.data > 0) {
          // Successful payment process
          this.generatedPaymentId = res.data;
          console.log(res.data);
  
          // Log user action
          let logGuid = localStorage.getItem("logGuid") || "";
          if (logGuid) {
            localStorage.removeItem("logGuid");
          }
  
          let logModel: IntakeFormLogModel = {
            logConnId: logGuid,
            userPhone: this.surveySubmissionDetails.patientDetails.phoneNo,
            userEmail: this.surveySubmissionDetails.patientDetails.email,
            userAction: `${this.surveySubmissionDetails.surveyDetails.surveyTypeName} -> ${this.surveySubmissionDetails.surveyDetails.surveyName} -> User found + Payment Page Process Successful with Amount: ${paymentDetails.amount} -> With PromoId: ${paymentDetails.promoId} -> With Card No: ${paymentDetails.cardNumber}`,
            userActionCode: paymentDetails.promoId == null ? 210 : 211,
            ipAddress: localStorage.getItem("clientIp"),
            browserName: this.adminService.detectBrowserName(),
            browserVersion: this.adminService.detectBrowserVersion(),
            clientMetaData: this.adminService.detectClientDeviceMetadata(),
            formData: "Payment: " + this.formDataCaptureString,
          };
          this.insertIntakeFormLog(logModel);
  
          // Clean up session storage
          sessionStorage.removeItem("selectedSurvey");
          sessionStorage.removeItem("procedureRate");
          sessionStorage.removeItem("existingSubmissionId");
          sessionStorage.removeItem("existingPatientId");
          localStorage.removeItem("intakeFormEmail");
  
          // Redirect if premium
          if (this.surveySubmissionDetails.patientDetails.ispremium === true) {
            window.location.href = "https://marketplace.drstellamd.com/Premium-autoship-order/";
          }
        } else if (res.error != null) {
          // Handle specific API errors
          this.isSubmitInProgress = false;
          this.openAlertDialog(res.error.errorMessage);
  
          // Log error action
          let logGuid = localStorage.getItem("logGuid") || "";
          if (logGuid) {
            localStorage.removeItem("logGuid");
          }
  
          let logModel: IntakeFormLogModel = {
            logConnId: logGuid,
            userPhone: this.surveySubmissionDetails.patientDetails.phoneNo,
            userEmail: this.surveySubmissionDetails.patientDetails.email,
            userAction: `${this.surveySubmissionDetails.surveyDetails.surveyTypeName} -> User found + Payment Page Process Issue: ${res.error.errorMessage}`,
            userActionCode: 221,
            ipAddress: localStorage.getItem("clientIp"),
            browserName: this.adminService.detectBrowserName(),
            browserVersion: this.adminService.detectBrowserVersion(),
            clientMetaData: this.adminService.detectClientDeviceMetadata(),
            formData: "Payment: " + this.formDataCaptureString,
          };
          this.insertIntakeFormLog(logModel);
          localStorage.removeItem("intakeFormEmail");
        } else {
          // Handle unexpected errors
          this.isSubmitInProgress = false;
          this.openAlertDialog("An unexpected error occurred.");
  
          // Log unexpected error
          let logGuid = localStorage.getItem("logGuid") || "";
          if (logGuid) {
            localStorage.removeItem("logGuid");
          }
  
          let logModel: IntakeFormLogModel = {
            logConnId: logGuid,
            userPhone: this.surveySubmissionDetails.patientDetails.phoneNo,
            userEmail: this.surveySubmissionDetails.patientDetails.email,
            userAction: `${this.surveySubmissionDetails.surveyDetails.surveyTypeName} -> User found + Payment Page Process Issue: Unexpected error`,
            userActionCode: 222,
            ipAddress: localStorage.getItem("clientIp"),
            browserName: this.adminService.detectBrowserName(),
            browserVersion: this.adminService.detectBrowserVersion(),
            clientMetaData: this.adminService.detectClientDeviceMetadata(),
            formData: "Payment: " + this.formDataCaptureString,
          };
          this.insertIntakeFormLog(logModel);
          localStorage.removeItem("intakeFormEmail");
        }
      },
      (error: HttpErrorResponse) => {
        // Check for status, message, and error content
        const errorStatus = error.status ? error.status : 'Unknown Status';
        const errorMessage = error.message ? error.message : 'Unknown Error Message';
        const errorDetails = error.error ? JSON.stringify(error.error) : 'No Error Details Provided';
    
        // Log error details
        console.error("Error Status:", errorStatus);
        console.error("Error Message:", errorMessage);
        console.error("Error Details:", errorDetails);
    
        // Alert user with a friendly message
        this.openAlertDialog("An error occurred while processing your request. Please try again later.");
    
        this.isSubmitInProgress = false;
      }
    );
  }
  

  openAlertDialog(alertMessage: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: alertMessage,
        buttonText: {
          cancel: "OK",
        },
      },
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.paymentForm.controls[controlName].hasError(errorName);
  };

  disallowSpace(event) {
    return event.keyCode != 32 ? event : event.preventDefault();
  }

  openEditAddressForm(element: any) {
    let data = { selectedRowData: this.patientSurveyModel };
    const dialogRef = this.dialog.open(PaymentAddressComponent, { data: data });
    dialogRef.afterClosed().subscribe((data) => {
      this.reloadAddress();
    });
  }

  insertIntakeFormLog(logModel: IntakeFormLogModel) {

    this.adminService.InsertIntakeFormLog(logModel).subscribe((data) => {
      if (data.error == null) {
        localStorage.setItem("logGuid", JSON.stringify(data.data));
      } else {
        //error in log
      }
    });
  }

}
