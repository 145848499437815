import { Injectable } from '@angular/core';
import { ServiceUrl } from '../service-url';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CompleteSurveyModel, patientSearchModel, SubcribeDetailModel } from 'src/app/Models/survey-details';
import { PromoPauoutModel } from 'src/app/Models/submission-details';
import { AcuteCareModel } from 'src/app/Models/Acute-Care';


@Injectable({
  providedIn: 'root'
})
export class SurveyServicesService {
  surveyServices: string;
  affiliateMarketingService: string;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private serviceUrl: ServiceUrl, private http: HttpClient) {
    this.surveyServices = this.serviceUrl.survey;
    this.affiliateMarketingService = this.serviceUrl.affiliateMarketing;
  }

  errorHandler(error: HttpErrorResponse) {
    let errorMessage = `Error code ${error.status}\nMessage: ${error.message}`;
    return throwError(errorMessage);
  }

  getSurveyType() {
    return this.http.get<any>(`${this.surveyServices}GetSurveyType`,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getQuestionBySurveyDetailsId(surveyDetailsId: number) {
    return this.http.get<any>(`${this.surveyServices}GetQuestionBySurveyDetailsId?surveyDetailsId=` + surveyDetailsId,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  createSurveySubmission(answers: any): Observable<any> {
    var url = `${this.surveyServices}CreateSurveySubmission`;

    var postData = {
      answers: answers
    };
    return this.http.post(url, postData, { headers: this.headers }).pipe(
      catchError(this.errorHandler));
  }

  getMedicinesBySurveyDetailsId(surveyDetailsId: number) {
    return this.http.get<any>(`${this.surveyServices}GetMedicinesBySurveyDetailsId?surveyDetailsId=` + surveyDetailsId,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getPromoDetailsByPromoName(promoName: string,email:string) {
    return this.http.get<any>(`${this.surveyServices}GetPromoDetailsByPromoName?promoName=` + promoName + `&email=` + email,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getPromoByPromoName(promoName: string) {
    return this.http.get<any>(`${this.surveyServices}GetPromoByPromoName?promoName=` + promoName,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  completeSurveyProcess(model: CompleteSurveyModel): Observable<any> {
    var url = `${this.surveyServices}CompleteSurveyProcess`;
    var postData = JSON.stringify(model);
    return this.http.post(url, postData, { headers: this.headers }).pipe(
      catchError(this.errorHandler));
  }
  completePremiumServiceProcess(model: CompleteSurveyModel): Observable<any> {
    var url = `${this.surveyServices}completePremiumServiceProcess`;
    var postData = JSON.stringify(model);
    return this.http.post(url, postData, { headers: this.headers }).pipe(
      catchError(this.errorHandler));
  }
  getGeneratedInvoice(paymentId: number) {
    return this.http.get<any>(`${this.surveyServices}GetGeneratedInvoice?paymentId=` + paymentId,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getGeneratedInvoice2(paymentId: number) {
    return this.http.get(`${this.surveyServices}GetGeneratedInvoice?paymentId=` + paymentId,
      { responseType: 'arraybuffer' }).pipe(catchError(this.errorHandler));
  }

  createSubmissionAndGetExistingPatientDetails(model: patientSearchModel): Observable<any> {
    var url = `${this.surveyServices}CreateSubmissionAndGetExistingPatientDetails`;
    var postData = JSON.stringify(model);
    return this.http.post(url, postData, { headers: this.headers }).pipe(
      catchError(this.errorHandler));
  }

  getPatientDetails(paymentId: number) {
    return this.http.get<any>(`${this.surveyServices}GetPatientDetails?paymentId=` + paymentId,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  GetOnlyPatientDetails(patientId: number): Observable<any> {
    var url = `${this.surveyServices}GetOnlyPatientDetails?patientId=` + patientId;
    //var postData = JSON.stringify(model);
    return this.http.post(url, { headers: this.headers }).pipe(
      catchError(this.errorHandler));
  }

  getExistingSurveyAsnwerBySubmissionId(submissionId: number, surveyDetailsId: number) {
    return this.http.get<any>(`${this.surveyServices}GetExistingSurveyAsnwerBySubmissionId?submissionId=` + submissionId + `&surveyDetailsId=` + surveyDetailsId,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getSubmissionIdByFirstNameAndEmail(firstName: string, email: string) {
    return this.http.get<any>(`${this.surveyServices}GetSubmissionIdByFirstNameAndEmail?firstName=` + firstName + `&email=` + email,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getAffiliateMarketingLoginOTP(email: string) {
    return this.http.get<any>(`${this.affiliateMarketingService}GetEmailOTP?email=` + email,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }
  getAffiliateMarketingPromotionDetails(email: string, startDate: string = "", endDate: string = "") {
    return this.http.get<any>(`${this.affiliateMarketingService}GetAffiliateMarketingPromotionDetails?email=` + email + "&startDate=" + startDate + "&endDate=" + endDate,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getPromoterList(applicationId: number) {
    return this.http.get<any>(`${this.affiliateMarketingService}GetPromoterList?applicationId=` + applicationId,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  updatePromoPaymentDetails(promoPauoutModel: PromoPauoutModel): Observable<any> {
    var url = `${this.affiliateMarketingService}UpdatePromoPaymentDetails`;
    var postData = JSON.stringify(promoPauoutModel);
    return this.http.post(url, postData, { headers: this.headers }).pipe(
      catchError(this.errorHandler));
  }


  getServiceOfferDetails(patientState: string): Observable<any> {
    return this.http.get<any>(`${this.surveyServices}GetServiceOfferDetails?patientState=` + patientState,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getAcuteCarePatientState(): Observable<any> {
    return this.http.get<any>(`${this.surveyServices}GetAcuteCarePatientState`,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getAcuteCarePharmacyDetails(telehealthId: number): Observable<any> {
    return this.http.get<any>(`${this.surveyServices}GetAcuteCarePharmacyDetails?surveyDetailsId=` + telehealthId,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getAcuteCareQuestionDetails(acuteCareServiceId: string): Observable<any> {
    return this.http.get<any>(`${this.surveyServices}GetAcuteCareQuestionDetails?serviceId=` + acuteCareServiceId,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  completeAcuteCareProcess(model: AcuteCareModel): Observable<any> {
    var url = `${this.surveyServices}CompleteAcuteCareProcess`;
    var postData = JSON.stringify(model);
    return this.http.post(url, postData, { headers: this.headers }).pipe(
      catchError(this.errorHandler));
  }

  getAcuteCareDetailsReport(startDate, endDate): Observable<any> {
    return this.http.get<any>(`${this.surveyServices}GetAcuteCareDetailsReport?startDate=` + startDate + `&endDate=` + endDate,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getAcuteCareSurveyReport(requestId: number): Observable<any> {
    return this.http.get<any>(`${this.surveyServices}GetAcuteCareSurveyReport?requestId=` + requestId,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getAcuteCarePayloadTemplate(requestId: number): Observable<any> {
    return this.http.get(`${this.surveyServices}GetAcuteCarePayloadTemplate?requestId=` + requestId,
      { responseType: 'arraybuffer' }).pipe(catchError(this.errorHandler));
  }

  identityfileUpload(formData: any): Observable<any> {
    var url = `${this.surveyServices}IdentityfileUpload`;
    return this.http.post(url, formData).pipe(
      catchError(this.errorHandler));
  }

  refundInitiate(chargeId: string, comingFrom: string): Observable<any> {
    return this.http.get(`${this.surveyServices}refundInitiate?chargeId=` + chargeId + `&comingFrom=` + comingFrom,
    { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getAcuteCarePatientApointmentStatus(referenceGuid: string): Observable<any> {
    return this.http.get(`${this.surveyServices}GetAcuteCarePatientApointmentStatus?referenceGuid=` + referenceGuid,
    { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getAcuteCareDrugList(): Observable<any> {
    return this.http.get(`${this.surveyServices}GetAcuteCareDrugList`,
    { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getAcuteCareRefillQuestions(): Observable<any> {
    return this.http.get(`${this.surveyServices}GetAcuteCareRefillQuestions`,
    { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  medicinefileUpload(formData: any): Observable<any> {
    var url = `${this.surveyServices}MedicinefileUpload`;
    return this.http.post(url, formData).pipe(
      catchError(this.errorHandler));
  }

  completeAcuteCareRefill(model: AcuteCareModel): Observable<any> {
    debugger;
    var url = `${this.surveyServices}CompleteAcuteCareRefill`;
    var postData = JSON.stringify(model);
    return this.http.post(url, postData, { headers: this.headers }).pipe(
      catchError(this.errorHandler));
  }

  getAcuteCareRefillMedicineDetails(): Observable<any> {
    return this.http.get(`${this.surveyServices}GetAcuteCareRefillMedicineDetails`,
    { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getServiceType() {
    debugger;
    return this.http.get<any>(`${this.surveyServices}GetServiceType`,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }
  
  // subscribeService(model: SubcribeDetailModel): Observable<any> {
  //   var url = `${this.surveyServices}ServiceSubscription`;
  //   var postData = JSON.stringify(model);
  //   return this.http.post(url, postData, { headers: this.headers }).pipe(
  //     catchError(this.errorHandler));
  // }
  getsubscriberService(email: string): Observable<any> {
    return this.http.get(`${this.surveyServices}getsubscriberService?email=${encodeURIComponent(email)}`, 
    { headers: this.headers }).pipe(catchError(this.errorHandler));  
  }
  getPatientIdByFirstNameAndEmail(firstName: string, email: string) {
    return this.http.get<any>(`${this.surveyServices}GetPatientIdByFirstNameAndEmail?firstName=` + firstName + `&email=` + email,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  getPremiumLoginOTP(firstName: string, email: string) {
    return this.http.get<any>(`${this.affiliateMarketingService}GetPremiumLoginOTP?firstName=` + firstName + `&email=` + email,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

}
