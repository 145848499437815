<div class="card inv-card">
    <button mat-icon-button class="close-button" (click)="closePayNowPopup(false)">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <div class="body">
        <div class="row" style="margin-bottom: 10px;">
            <strong>
                <p>
                    1.Please review cost details before clicking the “Pay Now Button”.
                </p>
                <p>
                    2. I understand that payment is final, there will be no refunds for purchases.
                </p>
                <p>
                    3. I acknowledge that payment is final, and I will not initiate refund.
                    <br/>

                    <mat-form-field appearance="standard">
                        <input placeholder="Put your initials or signature" matInput type="text">
                    </mat-form-field>

                    
                </p>
            </strong>
        </div>
        <div class="row" style="margin-bottom: 10px; margin-top: 10px;">
            <div class="col-md-12" style="text-align: right;">
                <button mat-raised-button color="primary" (click)="closePayNowPopup(true)">Pay Now</button>
            </div>
        </div>
        <div class="row">
            <section class="main-pd-wrapper" style="width: 100%; margin: auto; overflow-y: auto;">
                <table style="width: 100%;" class="inv-table">
                    <thead>
                        <tr>
                            <th style="width: 50px; padding-left: 0">Sn.</th>
                            <th style="width: 220px">Item Name</th>
                            <th>QTY</th>
                            <th style="text-align: right; padding-right: 0">Price ($)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="invoice-items" *ngFor="let data of invoiceData; let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>
                                {{data.name}}
                            </td>
                            <td style="text-align: right">
                                <div *ngIf="data.quantity && data.quantity > 0; else otherQuantityContent;">
                                    {{data.quantity}}
                                </div>
                                <ng-template #otherQuantityContent>
                                    
                                </ng-template>
                            </td>
                            <td style="text-align: right">
                                {{data.amount}}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot style="width: 100%; background: #fcbd024f; border-radius: 4px">
                        <tr>
                            <th>Total</th>
                            <th style="text-align: center">&nbsp;</th>
                            <th>&nbsp;</th>
                            <th style="text-align: right">$ {{totalAmount}}</th>
                        </tr>
                    </tfoot>
                </table>

                <!-- <table style="width: 100%; background: #fcbd024f; border-radius: 4px">
                    <thead>
                        <tr>
                            <th>Total</th>
                            <th style="text-align: center">&nbsp;</th>
                            <th>&nbsp;</th>
                            <th style="text-align: right">$ {{totalAmount}}</th>
                        </tr>
                    </thead>
                </table> -->
            </section>
        </div>
        
    </div>
</div>