
<div class="container-fluid col-lg-12 col-md-12 col-sm-12 text-end" style="width: 90%;">
    <!-- <a href="/productlist?category=All" style="float: right;" class="BuyNowButton btn btn-shop mb-4">
        Return to Products
    </a>
    <button class="autoship" (click)="ReturnToSubscription()">Return&nbsp;To&nbsp;Subscription</button> -->
</div>
<br/>
<br/>
<div class="container-fluid col-lg-12 col-md-12 col-sm-12 text-center" style="width: 90%;">
    <div class="mb-3">
        <p style="font-size: xx-large; font-weight: bold;">Premium Subscription Form</p>
    </div>
</div>
<form [formGroup]="formGroupProductCheckOutAddress">
    <div class="container-fluid pt-5 pb-5 body_container" style="width: 90%; background-color: white;">

        <div class="row">

           
            <!-- #region SHIPPING DETAILS -->
            <div class="col-12 col-sm-6 col-lg-6">
                <div class="row m-0">

                    <div>
                        <h2>Customer Details</h2>
                    </div>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>First Name<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsFirstName" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsFirstName', 'required')">First Name is
                            required.</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsFirstName', 'maxlength')">First Name cannot be more
                            than 50 characters.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Last Name<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsLastName" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsLastName', 'required')">Last Name is
                            required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsLastName', 'maxlength')">Last Name cannot be more
                            than 50 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Email <span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="email" placeholder="Type name"
                            formControlName="ShippingDetailsEmail" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsEmail', 'required')">Email is
                            required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsEmail', 'email')">Email address
                            not proper</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsEmail', 'maxlength')">Email address cannot be more
                            than 100 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Phone <span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="14" minlength="10" inputmode="numeric" placeholder="Type name"
                            formControlName="ShippingDetailsPhoneNo" (keypress)="allowOnlyDigits($event)" #nameInput
                            [mask]="'(000) 000-0000'" >
                        <mat-error *ngIf="checkError('ShippingDetailsPhoneNo', 'required')">Phone is
                            required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsPhoneNo', 'maxlength')">Phone cannot be more
                            than 10 characters</mat-error>
                       
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping Address 1 <span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="500" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsShippingAddress1" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingAddress1', 'required')">Shipping Address 1
                            is required.</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingAddress1', 'maxlength')">Shipping Address 1
                            cannot be more than 200 characters.</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping Address 2 <span style="color: red;"></span></mat-label>
                        <input matInput maxlength="500" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsShippingAddress2" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingAddress2', 'maxlength')">Shipping Address 2
                            cannot be more than 200 characters.</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping City<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsShippingCity" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingCity', 'required')">Shipping City
                            is required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingCity', 'maxlength')">Shipping City
                            cannot be more than 50 characters</mat-error>
                    </mat-form-field>

                    <!-- <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping State<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsShippingState" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingState', 'required')">Shipping State
                            is required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingState', 'maxlength')">Shipping State
                            cannot be more than 50 characters</mat-error>
                    </mat-form-field> -->
                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping State<span style="color: red;">*</span></mat-label>
                        <mat-select formControlName="ShippingDetailsShippingState">
                            <mat-option *ngFor="let state of states" [value]="state">
                                {{ state }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingState', 'required')">Shipping State
                            is required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Zip Code<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="5" inputmode="numeric" placeholder="Type name"
                            formControlName="ShippingDetailsZipcode" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsZipcode', 'required')">Zip Code
                            is required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsZipcode', 'maxlength')">Zip Code
                            cannot be more than 10 characters</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsZipcode', 'numeric')">Only numeric 
                            allowed</mat-error>
                    </mat-form-field>  
                </div>
            </div>
            <!-- #endregion -->

            <!-- #region BILLING DETAILS -->
            <div class="col-12 col-sm-6 col-lg-6">
                <div class="row m-0">

                    <div class="row">
                        <h2>Billing Details</h2>
                    </div>
                    <div style=" vertical-align: middle; word-wrap: break-word;" class="pt-3 pb-3">
                        <p>
                            <mat-slide-toggle class="pt-3 " formControlName="BillingDetailIsSameAsShippingAddress"
                                (change)="checkToggle()">
                                Same As Shipping Address</mat-slide-toggle>
                        </p>
                    </div>
                    <!-- </mat-form-field> -->

                    <mat-form-field appearance="fill" class="pt-4">
                        <mat-label>Billing Address <span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="500" inputmode="text" placeholder="Type name"
                            formControlName="BillingDetailsBillingAddress" [readonly]="isBillingDetailsReadonly">
                        <mat-error *ngIf="checkError('BillingDetailsBillingAddress', 'required')">Billing Address
                            is required</mat-error>
                        <mat-error *ngIf="checkError('BillingDetailsBillingAddress', 'maxlength')">Billing Address
                            cannot be more than 400 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Billing City<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="BillingDetailsCity" [readonly]="isBillingDetailsReadonly">
                        <mat-error *ngIf="checkError('BillingDetailsCity', 'required')">Billing City
                            is required</mat-error>
                        <mat-error *ngIf="checkError('BillingDetailsCity', 'maxlength')">Billing City
                            cannot be more than 50 characters</mat-error>
                    </mat-form-field>

                    <!-- <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Billing State<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="BillingDetailsState" [readonly]="isBillingDetailsReadonly">
                        <mat-error *ngIf="checkError('BillingDetailsState', 'required')">Billing State
                            is required</mat-error>
                        <mat-error *ngIf="checkError('BillingDetailsState', 'maxlength')">Billing State
                            cannot be more than 50 characters</mat-error>
                    </mat-form-field> -->
                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Billing State<span style="color: red;">*</span></mat-label>
                        <mat-select formControlName="BillingDetailsState">
                            <mat-option *ngFor="let state of states" [value]="state">
                                {{ state }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="checkError('BillingDetailsState', 'required')">Billing State
                            is required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Billing Zip Code<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="10" inputmode="numeric" placeholder="Type name"
                            formControlName="BillingDetailsZipcode" [readonly]="isBillingDetailsReadonly">
                        <mat-error *ngIf="checkError('BillingDetailsZipcode', 'required')">Billing Zip Code
                            is required</mat-error>
                        <mat-error *ngIf="checkError('BillingDetailsZipcode', 'maxlength')">Billing Zip Code
                            cannot be more than 10 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-2">
                        <mat-label>Promo Code</mat-label>
                        <input matInput maxlength="30" placeholder="Type Promocode" formControlName="Promocode">
                            
                            <mat-hint *ngIf="discount">Discount Applied: {{ discount }}%</mat-hint>
                    </mat-form-field>
                    <div *ngIf="promoError"><span style="color: red;">{{ promoError }}</span></div>
                    <div class="container-fluid">
                        <button class="autoship" type="button" (click)="applyPromocode()">Apply</button>
                        </div>                       

                    <div class="pt-3">
                        <label><b>How did you hear about us</b><span style="color: red;">*</span></label>
                        <mat-radio-group formControlName="FindAboutUs"  class="radio-group-vertical" (change)="onFindAboutUsChange($event.value)">
                            <mat-radio-button value="SearchEngine">Search Engine</mat-radio-button>
                            <mat-radio-button value="Friend">Recommended by friend or colleague</mat-radio-button>
                            <mat-radio-button value="SocialMedia">Social Media</mat-radio-button>
                            <mat-radio-button value="TV/Podcast">TV/Podcast</mat-radio-button>
                            <mat-radio-button value="Event">Event</mat-radio-button>
                            <mat-radio-button value="Other">Other</mat-radio-button>
                        </mat-radio-group>
                        
                        <!-- Conditionally display the textbox when "Other" is selected -->
                        <div *ngIf="isOtherSelected" class="pt-3">
                            <mat-form-field appearance="fill" style="background-color: rgba(0, 0, 0, .04);">
                                <mat-label>Please specify</mat-label>
                                <input matInput inputmode="text" placeholder="Type here..." formControlName="OtherDetails">
                            </mat-form-field>
                        </div>
                        <mat-error *ngIf="submitted && checkError('FindAboutUs', 'required')">
                            This field is required.
                        </mat-error>
                        
                    </div>
                    <div class="pt-3">
                        <label>
                          <mat-checkbox formControlName="declaration">
                            I hereby declare that all the information provided is true and correct.
                          </mat-checkbox>
                        </label>
                        <mat-error *ngIf="submitted && checkError('declaration', 'required')">
                            This field is required.
                        </mat-error>
                      </div>
                      <div class="pt-3">
                        <a href="javascript:void(0);" (click)="openTnCDialog()">Please Read and Acknowledge Our Terms and Policies Before Proceeding</a>
                        <mat-error *ngIf="submitted && checkError('acceptance', 'required')">
                            Please acknowledge Terms And Conditions
                          </mat-error>    
                      </div>
                </div>
            </div>
            <!-- #endregion -->
        </div>
        <br /><br />
        <div class="container-fluid modal-footer">
        <button class="autoship" type="submit" (click)="GetOtp()">&nbsp;&nbsp;Subscribe&nbsp;&nbsp;</button>
        </div>
    </div>
    <!-- </form> -->
    <ng-template #otpDialog>
        <div class="otp-dialog-container">
          <!-- Dialog Title -->
          <h2 mat-dialog-title class="otp-dialog-title">Verify Your Identity</h2>
      
          <mat-dialog-content class="otp-dialog-content">
            <!-- Instructions -->
            <p class="otp-instructions">
              We've sent a One-Time Password (OTP) to your email. Please enter the 6-digit code below to verify your identity.
            </p>
      
            <!-- OTP Input and Buttons -->
            <form [formGroup]="formGroupProductCheckOutAddress" class="otp-form">
              <div class="otp-input-group">
                <mat-form-field appearance="fill" class="otp-input">
                  <mat-label>Enter OTP</mat-label>
                  <input matInput formControlName="OTP" maxlength="6" placeholder="123456" />
                  <!-- Error for OTP mismatch -->
                  <mat-error *ngIf="isOtpMismatch">Invalid OTP. Please try again.</mat-error>
                  <!-- Error for empty OTP field -->
                  <mat-error *ngIf="formGroupProductCheckOutAddress.controls['OTP'].hasError('required')">
                    OTP is required.
                  </mat-error>
                </mat-form-field>
      
                <!-- Action Buttons Next to Input -->
                <div class="otp-buttons">
                  <button mat-button color="warn" (click)="onOtpDialogClose()">Cancel</button>
                  <button mat-flat-button color="primary" (click)="SubmitOtp()" 
                          [disabled]="formGroupProductCheckOutAddress.controls['OTP'].invalid">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </mat-dialog-content>
      
          <!-- Resend OTP Section -->
          <p class="otp-resend">
            Didn't receive the email? Check your spam folder or <a href="javascript:void(0);" (click)="GetOtp()">request a new OTP</a>.
          </p>
        </div>
      </ng-template>
       <!-- Terms and Conditions -->
       <ng-template #TnCDialog>
        <div class="otp-dialog-container">
          <!-- Dialog Title -->
          <h2 mat-dialog-title class="otp-dialog-title">Terms & Conditions</h2>
      
          <mat-dialog-content class="otp-dialog-content">
            <!-- Instructions -->
            
                <div class="otp-instructions">
                    <h2>DRStellaMD Subscription Services Terms and Policies</h2>
                    
                    <h3>1. Agreement to Terms</h3>
                    <p>
                      By subscribing to our services by checking the box below and proceeding with payment, you (“Patient”) acknowledge that you have read, understood, and agree to be bound by these Terms and Policies.
                    </p>
                  
                    <h3>2. Subscription Services</h3>
                    <p>
                      Patient will receive access to the following services as part of their monthly subscription fee of $199:
                      <a href="https://drstellamd.com/our-subscriptions/" target="_blank">https://drstellamd.com/our-subscriptions/</a>
                    </p>
                    <ol>
                      <li>1. 50% off acute care visits for covered services ($75 value)</li>
                      <li>2. 99% off covid/pandemic sick care visits ($285 value Cost of Medicine not included)</li>
                      <li>3. Free consult on refills (pharmacy fee not included)</li>
                      <li>4. Three free supplements of choice per month</li>
                      <li>5. Free unlimited consultation on Covid complete pack: ($150 value, medicine cost not included)</li>
                      <li>6. Free unlimited consultation on Antibiotic pack for emergency ($150 value, medicine cost not included)</li>
                      <li>7. Monthly Zoom Q and A with Dr Stella and other frontline doctors</li>
                      <li>8. Free triage calls to nurse practitioners from 9-4pm Monday to Friday</li>
                      <li>9. Free office visits at Rehoboth Medical Center in Houston with 20% discount on procedures and treatments.</li>
                      <li>10. 20% off marketplace products</li>
                    </ol>
                  
                    <h3>3. Monthly Fees and Payments</h3>
                    <p>
                      - The subscription fee of $199 is due on a monthly basis. <br />
                      - Payment is automatically charged to the payment method provided upon subscription. You authorize DRStellaMD to charge this account for the subscription fee.
                    </p>
                  
                    <h3>4. Cancellation and Refund Policy</h3>
                    <p>
                      - Patient may cancel their subscription at any time. Cancellations will take effect at the end of the current billing cycle. <br />
                      - All fees paid prior to cancellation are non-refundable.
                    </p>
                  
                    <h3>5. Patient Obligations</h3>
                    <p>
                        - The patient is responsible for maintaining accurate contact and billing information at all times. <br />
                        - When shopping online through DRStellaMD’s marketplace, the patient agrees to provide valid and accurate payment information for all purchases. <br />
                        - The patient agrees to review all orders before confirming purchases, as returns or refunds for certain products may not be permitted. <br />
                        - The patient acknowledges that any disputes regarding products or orders must be raised within 30 days of receiving the product.<br />
                        - The patient is responsible for covering any additional shipping or handling fees that may apply to certain products.
                      </p>
                  
                    <h3>6. Limitations of Liability</h3>
                    <p>
                      - DrStellaMD and its staff shall not be liable for any injuries, losses, or damages resulting from the use or inability to use the services provided. The Patient agrees to indemnify and hold harmless DRStellaMD from any claims or demands arising out of their use of services.
                    </p>
                  
                    <h3>7. Medical Emergencies</h3>
                    <p>
                      - Patients should not rely on our services for medical emergencies. In the event of a medical emergency, you should contact emergency services or go to the nearest hospital.
                    </p>
                  
                    <h3>8. Modifications to Terms</h3>
                    <p>
                      - DrStellaMD reserves the right to modify these Terms and Policies at any time. Patients will be notified of significant changes, and continued subscription will indicate acceptance of the revised terms.
                    </p>
                  
                    <h3>9. Governing Law</h3>
                    <p>
                      - These Terms and Policies shall be governed by the laws of Texas. Any disputes arising out of or related to these terms shall be resolved in accordance with those laws.
                    </p>
                  
                    <h3>10. Contact Information</h3>
                    <p>
                      For any questions regarding these Terms and Policies, please contact us at: <br />
                      - DRStellaMD <br />
                      - 832-808-5574 <br />
                      - patienthelpdesk@drstellamd.com
                    </p>
                    <label>
                        <mat-checkbox formControlName="acceptance">
                            I agree to the Terms and Policies of DRStellaMD.
                        </mat-checkbox>
                      </label>
                      
                                   
                      
                    <p><strong>Acknowledgment</strong><br />
                      By checking the box above and proceeding with payment, you acknowledge that you have read and understood these Terms and Policies and agree to abide by them.
                    </p>
                  </div>
                  <div class="otp-buttons">
                    <button mat-flat-button color="primary" (click)="onOtpDialogClose();">
                      Close
                    </button>
                  </div>
          </mat-dialog-content>
        </div>
      </ng-template>
      
</form>
<br />
<style>
    .radio-group-vertical mat-radio-button {
    display: block;
    margin-bottom: 10px; /* Adjust the spacing between each radio button */    
}
</style>